<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-10-05 10:24:31
 * @ Description: Component displaying the general profile account tab.
 -->

<template>
    <div>
        <!-- Desktop -->
        <v-row
            v-if="$vuetify.breakpoint.mdAndUp"
            style="position: relative;"
        >
            <!-- Add Card Button -->
            <v-btn
                style="position: absolute; right: 16px; top: -92px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                @click="createCard()"
            >
                Add Card
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
            <!-- Add Card Button -->

            <v-col
                cols="7"
                class="os-22-sb"
            >
                My Cards
            </v-col>
            <v-col
                cols="7"
                class="pt-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
                lg="11"
                class="pt-6"
            >
                <v-row>
                    <!-- No Cards -->
                    <v-col
                        v-if="profileCards === null || profileCards.length === 0"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                        cols="7"
                    >
                        No cards added
                    </v-col>
                    <!-- No Cards -->

                    <!-- My Cards -->
                    <v-col
                        cols="4"
                        lg="3"
                        v-for="(item, index) in profileCards"
                        :key="index"
                        v-else
                    >
                        <v-card
                            class="mx-auto rounded-lg pt-3"
                            elevation="0"
                            height="186"
                            max-height="186"
                            :width="$vuetify.breakpoint.lg ? '230' : '295'"
                            :max-width="$vuetify.breakpoint.lg ? '230' : '295'"
                            style="border: solid 1px #EFF4FA;"
                        >
                            <v-row
                                style="width: 100%; height: 100%;"
                                class="mx-auto"
                                align="center"
                            >
                                <v-col
                                    cols="12"
                                    class="os-12-r text-right"
                                    style="color: #707070; height: 42px; max-height: 42px; position: relative;"
                                >
                                    <span
                                        v-if="item.is_primary"
                                    >
                                        Primary Card
                                    </span>
                                    <v-btn
                                        icon
                                        v-else
                                        style="position: absolute; right: 4px; top: 4px;"
                                        @click="confirmDeleteCard(item)"
                                    >
                                        <v-icon
                                            color="black"
                                        >
                                            {{ mdiDeleteOutline }}
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                    <v-row>
                                        <v-col
                                            class="flex-grow-0 flex-shrink-1"
                                            align-self="center"
                                        >
                                            <v-img
                                                :src="item.brand === 'VISA' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountVisaLogo.png' : item.brand === 'MASTERCARD' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountMastercardLogo.png' : item.brand === 'AMEX' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountAmexLogo.png' : '/dashboard/profile/general/account/DashboardProfileGeneralAccountGenericCardLogo.png'"
                                                height="25px"
                                                width="40px"
                                                contain
                                            />
                                        </v-col>
                                        <v-col
                                            class="flex-grow-1 flex-shrink-0"
                                        >
                                            <div
                                                class="os-16-sb"
                                            >
                                                ****{{ item.number }}
                                            </div>
                                            <div
                                                class="os-12-r pt-0"
                                                style="color: #707070;"
                                            >
                                                {{ item.expiry.substring(0, 2) }}/{{ item.expiry.substring(2) }}
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pa-0 pt-3 mt-7"
                                >
                                    <v-divider />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-12-sb py-2 text-right"
                                    style="color: #2E567A; text-decoration: underline; cursor: pointer; min-height: 26px;"
                                >
                                    <span @click="confirmPrimaryCard(item)" v-if="!item.is_primary">Make Primary</span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <!-- My Cards -->
                </v-row>
            </v-col>

            <!-- Subscription Details -->
            <v-col
                cols="7"
                class="os-22-sb"
            >
                Subscription Details
            </v-col>
            <v-col
                cols="7"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="7"
                style="color: #222B45;"
            >
                <div
                    class="os-15-sb"
                >
                    Current Package: <span class="text-uppercase">{{ $store.state.user.userData.subscription_name }}</span>
                </div>
                <div
                    class="os-13-r py-4"
                >
                    Did you know that for a small monthly fee you can upgrade to any of our personal Fixer packages.  If you have any questions - please <span style="text-decoration: underline; cursor: pointer;">get in touch</span>!
                </div>
            </v-col>
            <!-- Subscription Details -->

            <!-- Pricing -->
            <v-col
                cols="12"
                lg="11"
            >
                <v-row>
                    <v-col
                        cols="4"
                        lg="3"
                        v-for="(item, index) in removeTrialPackage"
                        :key="index"
                    >
                        <v-card
                            class="mx-auto text-center"
                            elevation="0"
                            height="374"
                            max-height="374"
                            :width="$vuetify.breakpoint.lg ? '230' : '295'"
                            :max-width="$vuetify.breakpoint.lg ? '230' : '295'"
                        >
                            <v-row
                                style="width: 100%;"
                                class="elevation-1 mx-auto"
                            >
                                <v-col
                                    cols="12"
                                    class="os-27-sb"
                                    style="background: rgba(243, 170, 99, 0.11); color: #2E567A; height: 86px; display: flex; align-items: center; justify-content: center;"
                                >
                                    {{ item.description.name }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center pl-2 pr-2 os-14-r"
                                    style="color: #2E567A;"
                                    v-html="item.description.topText"
                                />
                                <v-col
                                    cols="12"
                                    class="py-0 text-center"
                                    style="display: flex; align-items: center; justify-content: center; color: #F3AA63; height: 60px; overflow-y: auto; font-size: 38px; font-weight: 700 !important; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;"
                                    :id="'fittext-' + index"
                                >
                                    {{ item.description.price }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="text-center pl-2 pr-2 os-14-r"
                                    style="color: #2E567A;"
                                >
                                    {{ item.description.bottomText }}
                                </v-col>
                                <v-col
                                    class="text-center pb-6"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-16-sb"
                                        color="#2E567A"
                                        style="width: 191px; height: 48px;"
                                        :style="$vuetify.breakpoint.lgAndUp ? 'width: 191px;' : 'width: 185px;'"
                                        @click="confirmUpgradePackage(item)"
                                        :disabled="item.is_current === 1"
                                    >
                                        Select
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <!-- Pricing -->

            <!-- Account Options -->
            <v-col
                cols="7"
                class="os-22-sb"
            >
                Account Options
            </v-col>
            <v-col
                cols="7"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="7"
                class="os-15-sb"
                style="text-decoration: underline; color: #222B45; cursor: pointer;"
                @click="confirmSuspendAccount"
            >
                Suspend Account
            </v-col>
            <!-- Account Options -->
        </v-row>
        <!-- Desktop -->

        <!-- Mobile -->
        <v-row
            v-else-if="$vuetify.breakpoint.smAndDown"
            style="position: relative;"
        >
            <!-- Add Card Button -->
            <v-btn
                style="position: absolute; right: 16px; top: -92px;"
                class="white--text no-uppercase rounded-lg os-13-sb"
                color="#FFA858"
                @click="createCard()"
            >
                <span v-if="!$store.state.general.customBreakpoints.xxs">Add Card</span>
                <v-icon
                    class="ml-1"
                >
                    {{ mdiPlusCircleOutline }}
                </v-icon>
            </v-btn>
            <!-- Add Card Button -->

            <v-col
                cols="12"
                class="os-22-sb"
            >
                My Cards
            </v-col>
            <v-col
                cols="12"
                class="pt-0"
            >
                <v-divider />
            </v-col>

            <!-- No Cards -->
            <v-col
                v-if="profileCards === null || profileCards.length === 0"
                class="os-12-r text-center"
                style="color: rgba(0, 0, 0, 0.38);"
            >
                No cards added
            </v-col>
            <!-- No Cards -->

            <!-- My Cards -->
            <v-col
                cols="12"
                sm="6"
                v-for="(item, index) in profileCards"
                :key="index"
                v-else
            >
                <v-card
                    class="mx-auto rounded-lg pt-3"
                    elevation="0"
                    height="185"
                    max-height="185"
                    :width="$vuetify.breakpoint.sm ? '240' : '250'"
                    :max-width="$vuetify.breakpoint.sm ? '240' : '250'"
                    style="border: solid 1px #EFF4FA;"
                >
                    <v-row
                        style="width: 100%; height: 100%;"
                        class="mx-auto"
                        align="center"
                    >
                        <v-col
                            cols="12"
                            class="os-12-r text-right"
                            style="color: #707070; height: 42px; max-height: 42px; position: relative;"
                        >
                            <span
                                v-if="item.is_primary"
                            >
                                Primary Card
                            </span>
                            <v-btn
                                icon
                                v-else
                                style="position: absolute; right: 4px; top: 4px;"
                                @click="confirmDeleteCard(item)"
                            >
                                <v-icon
                                    color="black"
                                >
                                    {{ mdiDeleteOutline }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="12"
                        >
                            <v-row>
                                <v-col
                                    class="flex-grow-0 flex-shrink-1"
                                >
                                    <v-img
                                        :src="item.brand === 'VISA' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountVisaLogo.png' : item.brand === 'MASTERCARD' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountMastercardLogo.png' : item.brand === 'AMEX' ? '/dashboard/profile/general/account/DashboardProfileGeneralAccountAmexLogopng' : '/dashboard/profile/general/account/DashboardProfileGeneralAccountGenericCardLogo.png'"
                                        height="25px"
                                        width="40px"
                                        contain
                                    />
                                </v-col>
                                <v-col
                                    class="flex-grow-1 flex-shrink-0"
                                >
                                    <div
                                        class="os-16-sb"
                                    >
                                        {{ item.brand }}
                                    </div>
                                    <div
                                        class="os-12-r pt-1"
                                        style="color: #707070;"
                                    >
                                        {{ item.number }}
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col
                            cols="12"
                            class="py-0 pt-3 mt-7"
                        >
                            <v-divider />
                        </v-col>
                        <v-col
                            cols="12"
                            class="os-12-sb py-1 text-right"
                            style="color: #2E567A; text-decoration: underline; cursor: pointer; min-height: 26px;"
                        >
                            <span @click="confirmPrimaryCard(item)" v-if="!item.is_primary">Make Primary</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <!-- No Cards -->

            <!-- Subscription Details -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Subscription Details
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
                style="color: #222B45;"
            >
                <div
                    class="os-15-sb"
                >
                    Current Package: <span class="text-uppercase">{{ $store.state.user.userData.subscription_name }}</span>
                </div>
                <div
                    class="os-13-r py-4"
                >
                    Did you know that for a small monthly fee you can upgrade to any of our personal Fixer packages.  If you have any questions - please <span style="text-decoration: underline; cursor: pointer;">get in touch</span>!
                </div>
            </v-col>
            <!-- Subscription Details -->

            <!-- Pricing -->
            <v-col
                cols="12"
                sm="6"
                v-for="(item, index) in removeTrialPackage"
                :key="index"
            >
                <v-card
                    class="mx-auto text-center"
                    elevation="0"
                    height="374"
                    max-height="374"
                    :width="$vuetify.breakpoint.sm ? '240' : '250'"
                    :max-width="$vuetify.breakpoint.sm ? '240' : '250'"
                >
                    <v-row
                        style="width: 100%;"
                        class="elevation-1 mx-auto"
                    >
                        <v-col
                            cols="12"
                            class="os-27-sb"
                            style="background: rgba(243, 170, 99, 0.11); color: #2E567A; height: 86px; display: flex; align-items: center; justify-content: center;"
                        >
                            {{ item.description.name }}
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center pl-2 pr-2 os-14-r"
                            style="color: #2E567A;"
                            v-html="item.description.topText"
                        />
                        <v-col
                            cols="12"
                            class="py-0 text-center"
                            style="display: flex; align-items: center; justify-content: center; color: #F3AA63; height: 60px; overflow-y: auto; font-size: 38px; font-weight: 700 !important; font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;"
                            :id="'fittext-' + index"
                        >
                            {{ item.description.price }}
                        </v-col>
                        <v-col
                            cols="12"
                            class="text-center pl-2 pr-2 os-14-r"
                            style="color: #2E567A;"
                        >
                            {{ item.description.bottomText }}
                        </v-col>
                        <v-col
                            class="text-center pb-6"
                        >
                            <v-btn
                                class="white--text no-uppercase rounded-lg os-16-sb"
                                color="#2E567A"
                                style="width: 191px; height: 48px;"
                                :style="$vuetify.breakpoint.lgAndUp ? 'width: 191px;' : 'width: 185px;'"
                                @click="confirmUpgradePackage(item)"
                                :disabled="item.is_current === 1"
                            >
                                Select
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <!-- Pricing -->

            <!-- Account Options -->
            <v-col
                cols="12"
                class="os-22-sb"
            >
                Account Options
            </v-col>
            <v-col
                cols="12"
                class="py-0"
            >
                <v-divider />
            </v-col>
            <v-col
                cols="12"
                class="os-15-sb"
                style="text-decoration: underline; color: #222B45; cursor: pointer;"
                @click="confirmSuspendAccount"
            >
                Suspend Account
            </v-col>
            <!-- Account Options -->
        </v-row>
        <!-- Mobile -->

        <!-- Payment Overlay -->
        <payment-overlay-component
            :showPaymentOverlayBoolean="showPaymentOverlayBoolean"
            :invoiceData="invoiceData"
            @showPaymentOverlay="showPaymentOverlay"
        />
        <!-- Payment Overlay -->

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'Card'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->

        <!-- Confirm Suspend Account Overlay -->
        <profile-general-account-confirm-suspend-overlay-component
            :showSuspendAccountConfirmationOverlayBoolean="showSuspendAccountConfirmationOverlayBoolean"
            @showSuspendAccountConfirmationOverlay="showSuspendAccountConfirmationOverlay"
        />
        <!-- Confirm Suspend Account Overlay -->

        <!-- Confirm Primary Card Change Overlay -->
        <profile-general-account-confirm-primary-card-overlay-component
            :showPrimaryCardConfirmationOverlayBoolean="showPrimaryCardConfirmationOverlayBoolean"
            @showPrimaryCardConfirmationOverlay="showPrimaryCardConfirmationOverlay"
        />
        <!-- Confirm Primary Card Change Overlay -->

        <!-- Subscription Updated Overlay -->
        <profile-general-account-subscription-updated-overlay-component
            :showSubscriptionUpdatedOverlayBoolean="showSubscriptionUpdatedOverlayBoolean"
            @showSubscriptionUpdatedOverlay="showSubscriptionUpdatedOverlay"
        />
        <!-- Subscription Updated Overlay -->
    </div>
</template>
<script>
    import PaymentOverlayComponent from '@/overlays/payment/PaymentOverlayComponent.vue'
    import ProfileGeneralAccountConfirmPrimaryCardOverlayComponent from '@/overlays/profile/general/account/ProfileGeneralAccountConfirmPrimaryCardOverlayComponent.vue'
    import { mdiPlusCircleOutline, mdiDeleteOutline } from '@mdi/js'
    import { GeneralProfileCardController, GeneralProfileController, GeneralProfileSubscriptionController, GeneralInvoiceController, GeneralProfileStatsController } from '@/logic/controller/index.controller.js'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'
    import ProfileGeneralAccountConfirmSuspendOverlayComponent from '@/overlays/profile/general/account/ProfileGeneralAccountConfirmSuspendOverlayComponent.vue'
    import { Capacitor } from '@capacitor/core'
    import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser'
    import ProfileGeneralAccountSubscriptionUpdatedOverlayComponent from '@/overlays/profile/general/account/ProfileGeneralAccountSubscriptionUpdatedOverlayComponent.vue'

    export default {
        name: 'ProfileAccountTabComponent',

        components: {
            PaymentOverlayComponent,
            ProfileGeneralAccountConfirmPrimaryCardOverlayComponent,
            GeneralConfirmDeleteOverlayComponent,
            ProfileGeneralAccountConfirmSuspendOverlayComponent,
            ProfileGeneralAccountSubscriptionUpdatedOverlayComponent
        },

        watch: {
            '$vuetify.breakpoint': {
                handler () {
                    this.$nextTick(() => {
                        this.$forceUpdate()
                    })
                },
                deep: true
            },

            showPaymentOverlayBoolean: {
                handler (value) {
                    if (!value && this.cardAdded) this.getProfileCards()
                }
            }
        },

        computed: {
            removeTrialPackage () {
                return this.pricingData.filter(function (e) {
                    return (e.subscription_id !== 1 && e.subscription_id !== 5)
                })
            }
        },

        data () {
            return {
                profileCards: [],
                showPaymentOverlayBoolean: false,
                invoiceData: {
                    item: {},
                    invoice: {}
                },
                mdiPlusCircleOutline,
                mdiDeleteOutline,
                generalProfileCardController: null,
                generalProfileController: null,
                generalProfileSubscriptionController: null,
                generalInvoiceController: null,
                generalProfileStatsController: null,
                sessionID: null,
                showDeleteConfirmationOverlayBoolean: false,
                cardToDelete: null,
                showPrimaryCardConfirmationOverlayBoolean: false,
                showSubscriptionUpdatedOverlayBoolean: false,
                cardToMakePrimary: null,
                showSuspendAccountConfirmationOverlayBoolean: false,
                pricingData: [],
                cardAdded: false
            }
        },

        beforeMount () {
            this.generalProfileCardController = new GeneralProfileCardController()
            this.generalProfileController = new GeneralProfileController()
            this.generalProfileSubscriptionController = new GeneralProfileSubscriptionController()
            this.generalInvoiceController = new GeneralInvoiceController()
            this.generalProfileStatsController = new GeneralProfileStatsController()
        },

        async mounted () {
            this.$nextTick(() => {
                for (let index = 0; index < this.pricingData.length; index++) {
                    var fittext = document.getElementById(`fittext-${index}`)
                    this.findFittextFontSize(fittext)
                }
            })

            await this.getProfileCards()
            await this.retrieveAccountPackage()
        },

        methods: {
            async createCard () {
                const self = this

                if (Capacitor.getPlatform() === 'android') {
                    try {
                        const paymentURL = await this.generalProfileCardController.createPaymentURL()

                        const inAppBrowser = InAppBrowser.create(paymentURL, '_blank')

                        inAppBrowser.on('loadstop').subscribe(event => {
                            self.onBrowserLoadStop()
                        })

                        inAppBrowser.on('loadstart').subscribe(async event => {
                            self.onBrowserLoadStart(event, inAppBrowser)
                        })

                        inAppBrowser.on('loaderror').subscribe(event => {
                            self.onBrowserError()
                        })

                        inAppBrowser.on('exit').subscribe(event => {
                            self.onBrowserClose()
                        })
                    } catch (error) {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })
                    }
                } else if (Capacitor.getPlatform() === 'web') {
                    try {
                        const payFastUUID = await this.generalProfileCardController.createCardSession()

                        window.payfast_do_onsite_payment(
                            { uuid: payFastUUID }, 
                            async function (result) {
                                if (result === true) {
                                    self.cardAdded = true
                                    await self.getProfileCards()
                                }
                                else {
                                    self.$store.commit('setSnackbar', {
                                        text: 'Add Card Cancelled',
                                        show: true
                                    })
                                }
                            })

                        this.cardAdded = false
                    } catch (error) {
                        this.$store.commit('setSnackbar', {
                            text: error,
                            show: true
                        })

                        this.cardAdded = false
                    }
                }
            },

            generateSignature (data, passPhrase = null) {
                const crypto = require('crypto')

                let queryParams = new URLSearchParams(data).toString()

                if (passPhrase) {
                    queryParams += `&passphrase=${encodeURIComponent(passPhrase).replace(/%20/g, '+')}`
                }

                return crypto.createHash('md5').update(queryParams).digest('hex')
            },

            onBrowserLoadStart (event, inAppBrowser) {
                if (event.url.includes('/payment/')) {
                    if (event.url.includes('/success')) {
                        this.cardAdded = true
                        inAppBrowser.close(false)
                    } else if (event.url.includes('/cancelled')) {
                        // this.cardAdded = false
                        inAppBrowser.close(false)
                    }
                } else if (event.url.includes('9to5fixer')) inAppBrowser.close(true)
            },

            onBrowserLoadStop () {
                // this.$store.commit('setSnackbar', {
                //     text: 'onBrowserLoadStop',
                //     show: true
                // })
            },

            onBrowserError () {
                this.$store.commit('setSnackbar', {
                    text: 'Error Creating Card',
                    show: true
                })
            },

            onBrowserClose (error) {
                if (error) {
                    this.$store.commit('setSnackbar', {
                        text: 'PayFast Error',
                        show: true
                    })
                } else {
                    if (this.cardAdded) {
                        this.getProfileCards()
                    } else {
                        this.$store.commit('setSnackbar', {
                            text: 'Add Card Cancelled',
                            show: true
                        })
                    }
                }

                this.cardAdded = false
            },

            async getProfileCards () {
                try {
                    this.profileCards = await this.generalProfileCardController.retrieve()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmDeleteCard (card) {
                this.cardToDelete = card
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteCard (card) {
                try {
                    this.profileCards = await this.generalProfileCardController.delete(card)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.cardToDelete = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) this.deleteCard(this.cardToDelete)
            },

            confirmPrimaryCard (card) {
                this.cardToMakePrimary = card
                this.showPrimaryCardConfirmationOverlay(true)
            },

            async makePrimaryCard (card) {
                try {
                    this.profileCards = await this.generalProfileCardController.makePrimary(card)

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })

                    this.cardToMakePrimary = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            confirmSuspendAccount () {
                this.showSuspendAccountConfirmationOverlay(true)
            },

            async suspendAccount () {
                try {
                    await this.generalProfileController.suspendAccount()

                    this.$store.commit('user/resetUserData')
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            showSuspendAccountConfirmationOverlay (value, confirmSuspend) {
                this.showSuspendAccountConfirmationOverlayBoolean = value

                if (confirmSuspend) this.suspendAccount()
            },

            showPrimaryCardConfirmationOverlay (value, confirmUpdate) {
                this.showPrimaryCardConfirmationOverlayBoolean = value

                if (confirmUpdate) this.makePrimaryCard(this.cardToMakePrimary)
            },

            showSubscriptionUpdatedOverlay (value) {
                // this.showSubscriptionUpdatedOverlayBoolean = value
            },

            showPaymentOverlay (value, confirmUpgrade, selectedCard) {
                this.showPaymentOverlayBoolean = value

                if (confirmUpgrade) this.payInvoice(this.invoiceData, selectedCard)
            },

            async retrieveAccountPackage () {
                try {
                    this.pricingData = await this.generalProfileSubscriptionController.retrieve()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async payInvoice (invoice, card) {
                try {
                    this.pricingData = await this.generalInvoiceController.pay(invoice.invoice.id, card.card_id)

                    this.$store.commit('setSnackbar', {
                        text: 'Updated successfully',
                        show: true,
                        color: '#069B34'
                    })

                    const userData = this.$store.state.user.userData
                    userData.subscription_id = this.invoiceData.item.subscription_id
                    userData.subscription_name = this.invoiceData.item.name

                    this.$store.commit('user/setUserData', userData)

                    await this.getProfileCompletionStats()

                    if (this.$store.state.user.userData.subscription_id === 1 || this.$store.state.user.userData.subscription_id === 5) {
                        let packageIndex = this.pricingData.findIndex(itemToFind => itemToFind.is_current === 1)
                        if (packageIndex > -1) {
                            this.pricingData[packageIndex].is_current = 0

                            packageIndex = this.pricingData.findIndex(itemToFind => itemToFind.subscription_id === this.$store.state.user.userData.subscription_id)
                            if (packageIndex > -1) this.pricingData[packageIndex].is_current = 1
                        }
                    }

                    this.invoiceData = {
                        item: {},
                        invoice: {}
                    }

                    this.showSubscriptionUpdatedOverlay(true)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getProfileCompletionStats () {
                try {
                    const stats = await this.generalProfileStatsController.getCompletionStats()
                    this.$store.commit('user/setUserProfileCompletionStats', stats)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async confirmUpgradePackage (upgradePackage) {
                try {
                    this.invoiceData = await this.generalProfileSubscriptionController.update(upgradePackage)
                    this.showPaymentOverlay(true)
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            findFittextFontSize (fittext) {
                this.$nextTick(() => {
                    if (fittext.scrollHeight > fittext.clientHeight) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    } else if (fittext.scrollWidth > fittext.clientWidth) {
                        this.$nextTick(() => {
                            var fontSize = fittext.style.fontSize
                            var fontSizeNumber = fontSize.split('px')[0]
                            fittext.style.fontSize = `${Number(fontSizeNumber - 1)}px`

                            this.findFittextFontSize(fittext)
                        })
                    }
                })
            }
        }
    }
</script>
